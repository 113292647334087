import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io';

interface SliderProps {
    images: string[];
    className?: string;
}

export const Slider: React.FC<SliderProps> = ({images, className = ''}) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const nextImage = () => {
        setCurrentIndex((prev) => (prev + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
    };

    React.useEffect(() => {
        if (images.length <= 1) return;

        const timer = setInterval(nextImage, 4000);
        return () => clearInterval(timer);
    }, [images.length]);

    return (
        <div className={`relative group ${className}`}>
            <AnimatePresence mode="wait">
                <motion.div
                    key={currentIndex}
                    className="relative w-full"
                    style={{paddingBottom: '56.25%'}} // 16:9 aspect ratio
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.5}}
                >
                    <img
                        src={images[currentIndex]}
                        alt={`Project Image ${currentIndex + 1}`}
                        className="absolute inset-0 w-full h-full object-contain bg-black/40"
                        loading="lazy"
                    />
                </motion.div>
            </AnimatePresence>

            {/* Navigation Buttons */}
            {images.length > 1 && (
                <>
                    <button
                        onClick={prevImage}
                        className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/70 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        aria-label="Previous image"
                    >
                        <IoIosArrowBack size={24}/>
                    </button>
                    <button
                        onClick={nextImage}
                        className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/70 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        aria-label="Next image"
                    >
                        <IoIosArrowForward size={24}/>
                    </button>
                </>
            )}

            {/* Image Counter */}
            {images.length > 1 && (
                <div className="absolute bottom-2 right-2 bg-black/50 px-3 py-1 rounded-full text-white text-sm">
                    {currentIndex + 1} / {images.length}
                </div>
            )}
        </div>
    );
};
