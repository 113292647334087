import React from 'react';
import {SiBuymeacoffee, SiFacebook, SiLinkedin} from "react-icons/si";
import {AiFillInstagram} from "react-icons/ai";
import {MyConstants} from "../../../constants/Constants";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
// @ts-ignore
import {Tables} from "../../../database.types";
import supabase from "../../../supabase/supabase-client";

export const OtherFooter = () => {
    const [settings, setSettings] = React.useState<Tables<'SettingsTable'>[] | null>(null);

    React.useEffect(() => {
        const fetchSettings = async () => {
            try {
                const {data, error} = await supabase
                    .from('SettingsTable')
                    .select('*')
                    .in('key', ['buymeacoffee', 'instagram', 'facebook', 'linkedin']);

                if (error) throw error;
                setSettings(data);
            } catch (error) {
                console.error('Error fetching footer settings:', error);
            }
        };

        fetchSettings();
    }, []);

    const getLink = React.useCallback((key: string) => {
        return settings?.find(setting => setting.key === key)?.value || '#';
    }, [settings]);

    const socialLinks = React.useMemo(() => [
        {
            key: 'buymeacoffee',
            icon: <SiBuymeacoffee
                className="w-10 h-10 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>,
            tooltip: "Buy me a Coffee"
        },
        {
            key: 'instagram',
            icon: <AiFillInstagram
                className="w-10 h-10 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>,
            tooltip: "Follow Us"
        },
        {
            key: 'facebook',
            icon: <SiFacebook
                className="w-9 h-9 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>,
            tooltip: "Follow Us"
        },
        {
            key: 'linkedin',
            icon: <SiLinkedin
                className="rounded-xl w-9 h-9 mx-2 duration-300 ease-in hover:scale-125 focus:scale-125 hover:rotate-12"/>,
            tooltip: "Follow Us"
        }
    ], []);

    const currentYear = React.useMemo(() => new Date().getFullYear(), []);

    return (
        <footer
            className="footer footer-center p-10 pb-32  bg-purple-700/80 text-primary-content overflow-x-hidden overflow-y-hidden">
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                data-aos="fade-up"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-duration="2000">
                <p className="font-extrabold text-2xl">
                    <Link to="/"
                          className="hover:text-slate-800 focus:text-slate-800 underline underline-offset-8 decoration-2 decoration-slate-800 transition duration-300 ease-in">
                        {MyConstants.DEVELOPER_NAME}
                    </Link>
                </p>
                <p>
                    Copyright © {currentYear}&nbsp;
                    <Link to="/">
                        {MyConstants.DEVELOPER_NAME}
                    </Link>
                </p>
            </motion.div>

            <div>
                <article className="flex flex-row justify-center">
                    {socialLinks.map((social) => (
                        <motion.div
                            key={social.key}
                            className="tooltip"
                            data-tip={social.tooltip}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <a href={getLink(social.key)}
                               target="_blank"
                               rel="noopener noreferrer">
                                {social.icon}
                            </a>
                        </motion.div>
                    ))}
                </article>
            </div>
        </footer>
    );
};