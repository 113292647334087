import {Route, Routes} from 'react-router-dom';
import {Header} from "./components/layouts/headers/Header";
import {Footer} from "./components/layouts/footers/Footer";
import Home from "./pages/Home";
import Skills from "./pages/Skills";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Experience from "./pages/Experience";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
// @ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import React, {useEffect, useState} from "react";
import {LoadingPage} from "./components/LoadingPage";
import {NotFound} from "./pages/NotFound";
import NorthSharpIcon from '@mui/icons-material/NorthSharp';

const App = () => {

    const [loadingPage, setLoadingPage] = useState(true);
    const [backToTopButton, setBackToTopButton] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setBackToTopButton(true);
            } else {
                setBackToTopButton(false);
            }
        })
        setLoadingPage(true);
        AOS.init();
        AOS.refresh();
        setTimeout(() => {
            setLoadingPage(false);
        }, 1900)
    }, []);

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }


    return (
        loadingPage ? <LoadingPage/>
            :
            <>
                {
                    backToTopButton && (
                        <button
                            data-aos="fade-left"
                            data-aos-duration="1400"
                            data-aos-easing="ease-in-out"
                            style={{
                                zIndex: "100",
                            }}
                            className={`bg-purple-800 text-slate-300 fixed bottom-20 md:bottom-10 right-5 rounded-xl p-2`}
                            onClick={scrollUp}>
                            <NorthSharpIcon/>
                        </button>
                    )}
                <Header/>
                <Routes>
                    <Route path="/" index element={<Home/>} key={1}/>
                    <Route path="/skills" element={<Skills/>} key={2}/>
                    <Route path="/services" element={<Services/>} key={3}/>
                    <Route path="/projects" element={<Projects/>} key={4}/>
                    <Route path="/experience" element={<Experience/>} key={5}/>
                    <Route path="/contact" element={<Contact/>} key={6}/>
                    <Route path="/support" element={<Support/>} key={7}/>
                    <Route path="*" element={<NotFound key={8}/>}/>
                </Routes>
                <Footer/>
            </>
    );
}

export default App;