import {motion} from "framer-motion";
import {SkillDash} from "./SkillDash";

export const SkillCard = (props: {
    totalSkills: number;
    subTitle?: string;
    title: string;
}) => {
    const dashCount = 5; // إجمالي عدد الشرطات
    const activeDashes = Math.round((props.totalSkills / 100) * dashCount);

    return (
        <motion.div className="w-80 bg-white/20 rounded-xl p-3 m-4 shadow-2xl shadow-black/80"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                    data-aos-mirror="true"
                    data-aos-once="false">
            <div className="flex justify-between">
                <h3 className="font-bold text-2xl ml-3 text-slate-300"
                    data-aos="fade-down"
                    data-aos-offset="100"
                    data-aos-easing="linear"
                    data-aos-duration="400"
                    data-aos-delay="200"
                    data-aos-mirror="true"
                    data-aos-once="false">
                    {props.title}
                    <span className="text-sm font-normal mx-1">
                        {props.subTitle}
                    </span>
                </h3>
                <span>
                    {props.totalSkills}%
                </span>
            </div>
            <div dir="ltr" className="flex space-x-2">
                {[...Array(dashCount)].map((_, index) => (
                    <SkillDash
                        key={index}
                        delayDash={500 + index * 100}
                        active={index < activeDashes}
                        tipTitle={index < activeDashes ? "20%" : "0%"}
                    />
                ))}
            </div>
        </motion.div>
    );
}