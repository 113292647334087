import React from "react";
import {Link} from "react-router-dom";
import {MyConstants} from "../../../constants/Constants";
import {motion} from "framer-motion";
import supabase from "../../../supabase/supabase-client";

export const Header = () => {
    const [cvLink, setCvLink] = React.useState<string>("#");

    React.useEffect(() => {
        const fetchCvLink = async () => {
            try {
                const {data, error} = await supabase
                    .from('SettingsTable')
                    .select('value')
                    .eq('key', 'CV')
                    .single();

                if (error) throw error;

                const link = data?.value || "#";
                setCvLink(link);
                sessionStorage.setItem('cvLink', link);
            } catch (error) {
                console.error('Error fetching CV link:', error);
            }
        };

        fetchCvLink();
    }, []);

    return (
        <div className="relative">
            <header className="navbar relative bg-black/40 shadow-2xl shadow-black/80 z-50">
                <motion.p
                    className="font-bold text-xl sm:font-extrabold sm:text-2xl mx-auto"
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >
                    <Link
                        to="/"
                        className="hover:text-purple-800 focus:text-slate-800 underline underline-offset-8 decoration-2 decoration-purple-800/70 transition duration-300 ease-in"
                    >
                        <p
                            data-aos="fade-down"
                            data-aos-duration="2000">
                            {MyConstants.DEVELOPER_NAME}
                        </p>
                    </Link>
                </motion.p>
            </header>

            <motion.div
                initial={{opacity: 0, x: 20}}
                animate={{opacity: 1, x: 0}}
                transition={{duration: 0.5, delay: 0.2}}
                className="absolute right-10 z-50 top-20 md:top-3"
            >
                <a
                    href={cvLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block"
                >
                    <button
                        className="py-2 px-3 flex items-center justify-center bg-purple-800/50 text-slate-300 font-bold rounded-xl hover:bg-purple-900 focus:bg-purple-900 transition-colors duration-300"
                    >
                        CV
                    </button>
                </a>
            </motion.div>
        </div>
    );
};