import {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import cat from "../../../images/iconsGif/cat.gif";
import {Li} from "../../Li";
import {HiAcademicCap, HiCode, HiCube, HiHeart, HiHome, HiMail, HiUser} from 'react-icons/hi';

export const MobileFooter = () => {
    const [isOn, setIsOn] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOn(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleSwitch = () => setIsOn(!isOn);
    const framer = {
        hidden: {
            height: 0,
            opacity: 0,
            delay: 0.2,
        },
        show: {
            height: "66vh",
            opacity: 100,
            transition: {
                type: "spring",
                stiffness: 100,
                delay: 0,
            },
        },
    }


    // @ts-ignore
    return (
        <motion.footer
            className="overflow-y-scroll overflow-x-hidden flex justify-center items-center z-50 select-none">

            {/* footer nav link */}
            {
                isOn ? (
                    <motion.nav
                        ref={menuRef}
                        className="bg-gradient-to-b from-gray-900/95 to-black/95 pt-8 border border-purple-500/40 shadow-lg shadow-purple-900/20 backdrop-blur-sm rounded-2xl w-[90%] md:w-1/2 xl:w-1/3 fixed bottom-3 flex ltr:flex-row rtl:flex-row-reverse justify-center"
                        variants={framer}
                        initial="hidden"
                        animate="show">
                        <div
                            className="btn btn-circle absolute -top-6 text-2xl bg-gradient-to-r from-purple-700 to-purple-900 hover:from-purple-800 hover:to-purple-950 border-2 border-purple-400/30"
                            onClick={toggleSwitch}>
                            <motion.span
                                className="tooltip pt-1"
                                data-tip="إغلاق القائمة"
                                whileHover={{scale: 1.2}}
                                whileTap={{scale: 0.9}}>
                                👇
                            </motion.span>
                        </div>

                        <div
                            className="absolute overflow-y-hidden -bottom-2 xl:-bottom-5 w-32 md:w-44  xl:w-64 opacity-60 bg-amber-20 h-24 xl:h-24">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={cat} alt="Cat Image" className="absolute bottom-0 md:-bottom-2"/>

                        </div>
                        <span className="absolute bottom-0 w-32 md:w-64 border-b border-slate-200"></span>


                        <motion.ul className="w-full space-y-1 pb-4" onClick={toggleSwitch}>
                            <Li title="رئيسية" icon={<HiHome
                                className="w-6 h-6 text-purple-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/"/>
                            <Li title="مهاراتي" icon={<HiUser
                                className="w-6 h-6 text-purple-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/skills"/>
                            <Li title="مؤهلات علمية" icon={<HiAcademicCap
                                className="w-6 h-6 text-purple-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/experience"/>
                            <Li title="مشاريع برمجية" icon={<HiCode
                                className="w-6 h-6 text-purple-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/projects"/>
                            <Li title="خدمات الكترونية" icon={<HiCube
                                className="w-6 h-6 text-purple-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/services"/>
                            <Li title="معرفات التواصل" icon={<HiMail
                                className="w-6 h-6 text-purple-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/contact"/>
                            <Li title="الدعم" icon={<HiHeart
                                className="w-6 h-6 text-pink-400 group-hover:text-white transition-colors duration-200"/>}
                                link="/support"/>
                        </motion.ul>


                    </motion.nav>
                ) : (
                    //	{/* footer buttons */}
                    <motion.div
                        ref={menuRef}
                        className="container  bg-purple-900/20  border border-purple-500/80 backdrop-blur-sm rounded-full w-[90%] md:w-1/2 xl:w-1/3 fixed bottom-3 py-2 flex ltr:flex-row rtl:flex-row-reverse justify-center"
                        initial={{opacity: 0.4}}
                        animate={{opacity: 1}}
                        transition={{
                            height: {duration: 3},
                            default: {ease: "easeIn"},
                            opacity: {duration: 1}
                        }}>
                        <button
                            data-isOn={isOn}
                            onClick={toggleSwitch}
                            className="tooltip tooltip-top btn btn-xs relative px-12 rounded-full bg-gradient-to-r from-purple-600 to-purple-800 hover:from-purple-700 hover:to-purple-900 font-extrabold text-3xl border border-purple-400/30"
                            data-tip="القائمة">
                            <span className="flex items-center gap-2">
                                <span className="w-1.5 h-1.5 bg-white rounded-full"/>
                                <span className="w-1.5 h-1.5 bg-white rounded-full"/>
                                <span className="w-1.5 h-1.5 bg-white rounded-full"/>
                            </span>
                        </button>
                    </motion.div>
                )
            }

        </motion.footer>
    );
}