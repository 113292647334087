import Timeline from "@mui/lab/Timeline";
import TimelineContent, {timelineContentClasses} from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";


export const TimeLineUI = (props: {
    title: string,
    body: string,
    index: string,
}) => {
    return (
        <Timeline position="right" className={`my-10 mx-auto md:w-[90%]`}
                  sx={{
                      [`& .${timelineContentClasses.root}`]: {
                          flex: window.innerWidth,
                      },
                  }}>
            <TimelineItem>
                <TimelineSeparator
                    data-aos="fade-left"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-duration="2000">
                    <TimelineConnector/>
                    <TimelineDot sx={{bgcolor: 'secondary.main'}}>
                        <span className={`w-6 h-6 font-bold text-slate-100`}>
                            {
                                props.index
                            }
                        </span>
                    </TimelineDot>
                    <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent sx={{py: '12px', px: 2}}
                                 data-aos="fade-up"
                                 data-aos-mirror="true"
                                 data-aos-once="false"
                                 data-aos-duration="2000">
                    <p
                        className={`text-3xl text-right w-fit px-4 py-1 border-b-2 border-r-4 border-purple-600 rounded-r-md font-bold  text-slate-300`}>
                        {
                            props.title
                        }
                    </p>
                    <p className={`my-4 text-right font-medium  text-xl md:text-2xl text-slate-300 `}>
                        {
                            props.body
                        }
                    </p>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}