import {NavLink} from "react-router-dom";
import {motion} from "framer-motion";


export const Li = (props: {
    title: string;
    link: string;
    icon: any;
}) => {

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }

    // @ts-ignore
    const navLinkStyle = ({isActive}) => {
        return {
            backgroundColor: isActive ? "rgb(126,34,206)" : "",
            opacity: isActive ? 1 : 0.8,
        }
    }

    return (
        <NavLink to={props.link}
                 style={navLinkStyle}
                 onClick={scrollUp}
                 className={`border-b border-slate-700 border-r-4 border-r-purple-700 text-slate-300 cursor-pointer my-2 py-2 w-full block text-xl 3xl:text-4xl md:text-md font-medium hover:bg-purple-800 focus:bg-purple-700 hover:text-white focus:text-base-300`}>

            <motion.div whileHover={{x: -8}}
                        whileTap={{x: -8}}
                        className={`px-5`}>

			    <span className="rtl:ml-3 ltr:mr-3 inline-block -mb-0.5">
			        {props.icon}
			    </span>
                <span>
                    {props.title}
                </span>

            </motion.div>
        </NavLink>
    );
}