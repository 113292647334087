import {ScientificSkills} from "./ScientificSkills";
import {PersonalSkills} from "./PersonalSkills";

export const SkillsComponents = () => {
    return (
        <div className="mb-20 xl:mb-72 w-[99%] md:w-[80%] select-none mx-auto">
            {/* Scientific Skill Skill */}
            <ScientificSkills/>
            {/* Scientific Skill Skill */}

            {/* Start Personal Skill */}
            <PersonalSkills/>
            {/* End Personal Skill */}
        </div>
    );
}