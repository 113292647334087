import "./CardProject.css";
import {useRef} from "react";
import {motion, useScroll, useTransform} from "framer-motion";
import {Slider} from "../sliderImage/Slider";

interface CardScrollProps {
    id: number;
    images: string[];
    title: string;
    body: string;
    link?: string;
    linkGitHub?: string;
}

function useParallax(value: any, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

function Image({id, images, title, body, link, linkGitHub}: CardScrollProps) {
    const ref = useRef(null);
    const {scrollYProgress} = useScroll({target: ref});
    const y = useParallax(scrollYProgress, 190);

    // تنسيق رقم المشروع
    const formattedId = id < 10 ? `0${id}` : id.toString();

    return (
        <section className="card-project relative mx-auto min-h-screen pt-32 overflow-hidden" ref={ref}>
            {/* Container for all content */}
            <div className="w-[90vw] sm:w-[80vw] xl:w-[60vw] mx-auto">
                {/* Image Container */}
                <div className="relative mb-8">
                    <div className="w-full max-w-[800px] mx-auto bg-black/20 rounded-xl overflow-hidden">
                        <Slider
                            images={images}
                            className="rounded-xl shadow-2xl"
                        />
                    </div>
                </div>

                {/* Content Container */}
                <div className="space-y-6 px-4 md:px-8">
                    <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold text-slate-300 border-r-4 border-purple-800 pr-4 rounded-sm">
                        {title}
                    </h2>

                    <p className="text-lg md:text-xl xl:text-2xl font-medium text-slate-300 leading-relaxed">
                        {body}
                    </p>

                    {/* Links Container */}
                    <div className="flex flex-wrap gap-4 justify-end pt-4">
                        {link && (
                            <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-xl text-purple-500 hover:text-purple-400 transition-colors duration-300"
                            >الذهاب للموقع</a>
                        )}
                        {linkGitHub && (
                            <a
                                href={linkGitHub}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-xl text-purple-500 hover:text-purple-400 transition-colors duration-300"
                            > - GitHub &nbsp; &nbsp;</a>
                        )}
                    </div>
                </div>
            </div>

            {/* Project Number */}
            <motion.h2
                className="text-slate-100 text-3xl md:text-4xl xl:text-5xl font-bold absolute right-4 md:right-8 top-20"
                style={{y}}
            >
                #{formattedId}
            </motion.h2>
        </section>
    );
}

// Main Component
const CardScroll = (props: {
    index: number;
    image: string[];
    title: string;
    body: string;
    link?: string;
    linkGitHub?: string;
}) => {
    return (
        <Image
            id={props.index}
            images={props.image}
            title={props.title}
            body={props.body}
            link={props.link}
            linkGitHub={props.linkGitHub}
        />
    );
};

export default CardScroll;
