import {motion} from "framer-motion";
import {SkillsComponents} from "../components/skills/SkillsComponents";
import "../index.css";

const Skills = () => {

    return (
        <motion.div
            initial={{y: "100%", opacity: 0}}
            animate={{y: "0%", opacity: 1}}
            transition={{duration: 1.5, ease: "easeOut"}}
            className="min-h-screen pb-32 pt-10 w[90%] xl:pb-10 pattern-about">

            {/* start skills section */}
            <SkillsComponents/>
            {/* end skills section */}

        </motion.div>
    );
}


export default Skills;