import {motion} from "framer-motion";
import {Char} from "./Char";
import {RightToCenter} from "./effect/RightToCenter";
import {BottomToCenter} from "./effect/BottomToCenter";
import {Link} from "react-router-dom";

export const CardWhite = (props: { title: string; desAr: string; desEn: string; text: string; }) => {

    // effect
    return (
        <div>
            <motion.article
                variants={RightToCenter}
                initial="hidden"
                animate="show"
                className="bg-white/10 rounded-xl p-4 max-w-2xl mx-auto xl:mr-[40%] xl:relative xl:-top-32">


                <div>
                    <h1
                        data-aos="fade-right"
                        data-aos-offset="50"
                        data-aos-easing="linear"
                        data-aos-duration="2200"
                        data-aos-delay={30}
                        className="font-extrabold text-3xl select-none p-4">
                        {
                            props.title.split('').map((char: any) => {
                                return <Char char={char}/>
                            })
                        }
                    </h1>

                    {/*<h2*/}
                    {/*    data-aos="fade-right"*/}
                    {/*    data-aos-offset="50"*/}
                    {/*    data-aos-easing="linear"*/}
                    {/*    data-aos-duration="2700"*/}
                    {/*    className="mx-6 mt-2 font-medium select-none">*/}
                    {/*    {*/}
                    {/*        props.desAr.split('').map((char: any) => {*/}
                    {/*            return <Char char={char}/>*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</h2>*/}
                    <h2
                        data-aos="fade-right"
                        data-aos-offset="50"
                        data-aos-easing="linear"
                        data-aos-duration="3000"
                        className="mx-4 font-bold select-none">
                        {
                            props.desEn.split('').map((char: any) => {
                                return <Char char={char}/>
                            })
                        }
                    </h2>
                    <br/>
                    <hr/>
                    <motion.div
                        variants={BottomToCenter}
                        initial="hidden"
                        animate="show"
                        className="tracking-wider mt-5 text-slate-200/90 font-medium text-xl select-none">

                        {
                            props.text.split('').map((char: any) => {
                                return <Char char={char}/>
                            })
                        }
                        <p
                            data-aos="fade-right"
                            data-aos-offset="50"
                            data-aos-easing="linear"
                            data-aos-duration="4000"
                            className="transition duration-500 ease-linear  px-2 my-2 hover:bg-purple-600/30 rounded-xl w-fit underline decoration-dashed decoration-4 underline-offset-8 decoration-purple-500 font-medium">
                            <Link to="/contact">
                                معرفات التواصل</Link>
                        </p>
                    </motion.div>

                </div>
            </motion.article>
        </div>
    );
}