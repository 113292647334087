import "../index.css"
import {DrawInHomePage} from "../components/DrawInHomePage";
import {motion} from "framer-motion";
import {RightToCenter} from "../components/effect/RightToCenter";
import {CardWhite} from "../components/CardWhite";
import cat from "../images/iconsGif/cat.gif";
import supabase from "../supabase/supabase-client";
import React, {useEffect, useState} from "react";
// @ts-ignore
import {Tables} from "../../database.types";
import {useContactInfo} from "../components/contact/useContactInfo";
import {FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaMugHot} from "react-icons/fa";


const Home = () => {
    const [aboutMe, setAboutMe] = useState<Tables<'AboutMeTable'> | null>(null);
    const {contactInfo} = useContactInfo();
    const socialItems = [
        {
            icon: <FaInstagram className="text-3xl"/>,
            label: "Instagram",
            href: contactInfo?.social_media.instagram,
            color: "hover:text-pink-500",
        },
        {
            icon: <FaFacebook className="text-3xl"/>,
            label: "Facebook",
            href: contactInfo?.social_media.facebook,
            color: "hover:text-blue-500",
        },
        {
            icon: <FaGithub className="text-3xl"/>,
            label: "GitHub",
            href: contactInfo?.social_media.github,
            color: "hover:text-gray-400",
        },
        {
            icon: <FaLinkedin className="text-3xl"/>,
            label: "LinkedIn",
            href: contactInfo?.social_media.linkedin,
            color: "hover:text-blue-600",
        },
        {
            icon: <FaMugHot className="text-3xl"/>,
            label: "Buy me a coffee",
            href: contactInfo?.social_media.buymeacoffee,
            color: "hover:text-yellow-500",
        },
    ];

    useEffect(() => {
        const fetchAboutMe = async () => {
            try {
                const {data, error} = await supabase.from('AboutMeTable').select('*').single();

                if (error) throw error;
                setAboutMe(data);
            } catch (error) {
                console.error('Error fetching about me data:', error);
            }
        };

        fetchAboutMe();
    }, []);

    if (!aboutMe) return <div className="min-h-screen"></div>; // Early return if data isn't loaded

    return (
        <div className="relative ">
            <main className="min-h-screen py-32 relative overflow-hidden pattern-main">
                <DrawInHomePage/>

                <div
                    className="container absolute -top-28 right-[8%] xl:right-[5%] w-[80%] md:w-[90%] xl:w-[100%] xl:mr-20 mt-36 md:mt-22 xl:mt-10">
                    <section>
                        <ProfileAvatar avatarUrl={aboutMe.avatar!}/>
                        <CardWhite
                            title={aboutMe.name ?? ''}
                            desAr={aboutMe.job_ar}
                            desEn={aboutMe.job_en}
                            text={aboutMe.description}
                        />
                    </section>
                    {/*<article className="mt-10 xl:mt-0">*/}
                    {/*    <ContactLinks/>*/}
                    {/*</article>*/}


                    {/* Social Media Section */}
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.6}}
                        className="mb-12 bg-gray-900/40 rounded-xl p-6 my-20 backdrop-blur-sm border border-gray-700/50"
                    >
                        <h2 className="text-2xl text-white text-center mb-6">معرفات التواصل</h2>
                        <div className="flex flex-wrap justify-center gap-8">
                            {socialItems.map((item, index) => (
                                <motion.a
                                    key={item.label}
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    initial={{opacity: 0, scale: 0}}
                                    animate={{opacity: 1, scale: 1}}
                                    transition={{delay: 1 + index * 0.1}}
                                    className={`text-white ${item.color} hover:scale-125 transition-all duration-700`}
                                    title={item.label}
                                >
                                    {item.icon}
                                </motion.a>
                            ))}
                        </div>
                    </motion.div>
                </div>
            </main>

            <CatAnimation/>
        </div>
    );
};

// Extracted components for better organization
const ProfileAvatar = ({avatarUrl}: { avatarUrl?: string }) => (
    <article className="avatar md:w-1/2 xl:w-1/3 md:right-1/4 xl:right-0 xl:top-48">
        <motion.div
            variants={RightToCenter}
            initial="hidden"
            animate="show"
            className="w-[70%] mb-10 rounded-full mx-auto ring ring-purple-800 ring-offset-base-100 ring-offset-2"
        >
            {avatarUrl && <img src={avatarUrl} alt="Profile" loading="lazy"/>}
        </motion.div>
    </article>
);

const CatAnimation = () => (
    <>
        <img
            src={cat}
            alt="Cat Animation"
            loading="lazy"
            className="hidden xl:block absolute bottom-11 w-32 md:w-64"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1400"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="bottom"
        />
        <span className="hidden xl:block absolute bottom-12 w-32 md:w-64 border-t-2 border-purple-500">
            &nbsp;
        </span>
    </>
);

export default Home;