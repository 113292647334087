import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {motion} from 'framer-motion';
// @ts-ignore
import {Tables} from "../../database.types";
import supabase from "../supabase/supabase-client";

const Experience = () => {
    const [experiences, setExperiences] = React.useState<Tables<'ExperienceTable'>[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchExperiences = async () => {
            try {
                const {data, error} = await supabase
                    .from('ExperienceTable')
                    .select('*')
                    .order('start_date', {ascending: false});

                if (error) throw error;
                setExperiences(data || []);
            } catch (error) {
                console.error('Error fetching experiences:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchExperiences();
    }, []);

    if (isLoading) {
        return <TimelineLoader/>;
    }

    return (
        <div
            className="min-h-screen overflow-x-hidden relative flex justify-center items-center py-10 md:py-20 bg-experience">
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.5}}
                className="w-full md:w-4/5 lg:w-3/4 px-4 md:px-0"
            >
                <Timeline position="right" className="p-0 md:p-4">
                    {experiences.map((experience, index) => (
                        <TimelineItem
                            key={experience.id}
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            <TimelineOppositeContent
                                sx={{
                                    m: 'auto 0',
                                    flex: {xs: 0.3, md: 0.2},
                                    padding: {xs: '0 8px', md: '0 16px'}
                                }}
                                align="right"
                                variant="body2"
                            >
                                <motion.div
                                    initial={{opacity: 0, x: -20}}
                                    animate={{opacity: 1, x: 0}}
                                    transition={{delay: index * 0.2}}
                                    className="bg-purple-900/30 p-2 rounded-lg  font-bold text-sm md:text-lg"
                                >
                                    {experience.start_date}
                                    <br/>
                                    <span className="text-purple-300 text-xs md:text-base">
                                        {experience.end_date || 'إلى الأبد 🌵'}
                                    </span>
                                </motion.div>
                            </TimelineOppositeContent>

                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        borderWidth: 2,
                                        borderColor: '#9333ea',
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                />
                                <TimelineConnector
                                    sx={{
                                        bgcolor: '#9333ea',
                                        width: {xs: '2px', md: '3px'}
                                    }}
                                />
                            </TimelineSeparator>

                            <TimelineContent
                                sx={{
                                    py: {xs: '8px', md: '12px'},
                                    px: {xs: 1, md: 2},
                                    flex: {xs: 0.7, md: 0.8}
                                }}
                            >
                                <motion.div
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.2}}
                                    className="mr-2 md:mr-4"
                                >
                                    <h2 className="text-white/80 text-right text-lg md:text-2xl font-bold border-r-4 md:border-r-8 border-b-2 md:border-b-4 w-fit border-purple-800 p-2 md:p-3 rounded-xl md:rounded-2xl bg-black/40 mb-2 md:mb-4">
                                        {experience.title}
                                    </h2>
                                    <p className="text-right text-base md:text-xl font-medium leading-relaxed md:leading-loose mt-2 bg-black/20 p-2 md:p-4 rounded-lg">
                                        {experience.description}
                                    </p>
                                </motion.div>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </motion.div>
        </div>
    );
};

const TimelineLoader = () => (
    <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 md:h-16 md:w-16 border-t-4 border-b-4 border-purple-500"/>
    </div>
);

export default Experience;
