import {ContactForm} from "../components/contact-form";

const Support = () => {
    return (
        <div className={`min-h-screen   pt-32 pb-20`}>

            <div>
                <div className={`text-8xl font-bold text-center `}>
                    <p className={`py-2`}>❤️ ❤️</p>
                    <p>Thanks Me</p>
                    <p className={`pb-4`}>و</p>
                    <p>Thanks You</p>
                </div>

                <ContactForm/>
            </div>
        </div>
    );
};

export default Support;