import {createClient} from "@supabase/supabase-js";
// @ts-ignore
import {Database} from "../../database.types";


const SUPABASE_URL = process.env.REACT_APP_PUBLIC_URL ?? '';
const SUPABASE_ANON_KEY = process.env.REACT_APP_ANON_KEY ?? '';

const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY);

export default supabase;