import {motion} from "framer-motion";
import {AiOutlineDoubleLeft} from "react-icons/ai";
import {TimeLineUI} from "../components/material-ui/TimeLineUI";
import {Link} from "react-router-dom";
import React from 'react';
import {ContactForm} from "../components/contact-form";

const moveRightLeft = {
    hidden: {opacity: 0},
    show: {
        opacity: [0.5, 1, 0.5],
        x: ["0%", "120%", "0%"],
        transition: {
            duration: 5,
            ease: "easeInOut",
            repeat: Infinity,
        }
    }
}


const Services = () => {

    return (
        <motion.div
            initial={{x: "100%", opacity: 0}}
            animate={{x: "0%", opacity: 1}}
            transition={{duration: 0.75, ease: "easeOut"}}
            exit={{opacity: 1}}
            className="min-h-screen pt-20 pattern-services text-center select-none overflow-x-hidden">
            {/*<p className={` my-3 text-xl font-bold py-1 px-10 w-fit mx-auto rounded-xl bg-black/60`}>متوفر قريباً</p>*/}
            <h1 className="text-center text-xl sm:text-xl md:text-4xl flex flex-col space-y-7">

                <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-offset="20"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    className="flex  items-center mx-auto">
                    <motion.span
                        variants={moveRightLeft}
                        initial="hidden"
                        animate="show">
                        <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
                    </motion.span>
                    <span>
					عندك فكرة ببالك وحابب تعملها حقيقة؟🤔
				</span>
                </p>


                <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-offset="40"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    className="flex items-center mx-auto">
                    <motion.span
                        variants={moveRightLeft}
                        initial="hidden"
                        animate="show">
                        <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
                    </motion.span>
                    بدك تعمل تطبيق لعملك ومحتار كيف؟🤩
                </p>

                <p
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-offset="40"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    className="flex items-center mx-auto">
                    <motion.span
                        variants={moveRightLeft}
                        initial="hidden"
                        animate="show">
                        <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
                    </motion.span>
                    <div className="flex flex-wrap">
                        <span>&nbsp; الحل عنا, فقط تواصل معنا&nbsp;</span>
                        <strong
                            data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-offset="100"
                            data-aos-duration="3000"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            className="decoration-2 underline underline-offset-4 decoration-purple-600 mx-auto sm:mx-3">
                            خدماتنا الرائعة
                        </strong>
                    </div>
                </p>
            </h1>


            {/* Start Contact form */}
            <ContactForm/>
            {/* End Contact form */}


            {/* Start Last Section Service*/}
            <section>
                <h2
                    data-aos="fade-left"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-duration="1500"
                    className={`mt-40 text-xl  md:text-4xl mx-4 font-bold leading-loose tracking-wide border-x-8 rounded-md border-purple-600`}>
                    بالتأكيد يجب علينا إطلاعكم على طريقة تنفيذ المشاريع لدينا
                </h2>


                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`1`}
                    title={`تحديد الفكرة ودراستها:`}
                    body={`تعتبر أكثر الخطوات أهمية، حيث نقوم بتحليل فكرة المشروع الأساسية ودراستها لأنها الأساس الذي يدور حوله كل شيء`}
                />
                {/* End TimeLine Item Service */}


                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`2`}
                    title={`تصميم المشروع:`}
                    body={`نحرص على بناء تصميمات مميزة للمشاريع لنضمن تجربة مستخدم لا مثيل لها، ويكتمل إبداع فريقنا عند حرصه على بناء مشاريع تجذب المستخدمين لمنتجاتك أو خدماتك المعروضة  مرارًا وتكرارًا`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`3`}
                    title={`مرحلة البرمجة:`}
                    body={`نقدم حلول عالية الجودة باستخدام أحدث التقنيات المتطورة حتى تحصل في النهاية على مشروع فعّال ومتكامل بأقل التكاليف`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`4`}
                    title={`اختبار التطبيق قبل الإطلاق`}
                    body={`نحن هنا لتوفير أفضل الخدمات لك، ونضمن لك التزامنا بوعودنا فيما يخص حصولك على مشروع ذات مواصفات قياسية ولا يوجد به أية عيوب`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`5`}
                    title={`إطلاق المشروع:`}
                    body={`نقوم بإطلاق مشروعك على الإنترنت بكل ثقة في النجاح لأننا متأكدين من جودة أعمالنا، كما نبقى معك طوال مدة الإطلاق والنشر لنساعدك على تحقيق أهدافك`}
                />
                {/* End TimeLine Item Service */}

                {/* Start TimeLine Item Service */}
                <TimeLineUI
                    index={`6`}
                    title={`التسويق للتطبيق:`}
                    body={`بعد الإطلاق سيقوم خبراء التسويق لدينا بوضع أفضل خطط التسويق لمساعدتك في تطوير أعمالك وإقناع العميل المستهدف بشراء المنتج أو الخدمة التي تقدمها من خلال التطبيق`}
                />
                {/* End TimeLine Item Service */}


                <h3
                    data-aos="fade-left"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-duration="1500"
                    className={`text-xl mb-40  md:text-4xl mx-4 mt-20 font-bold leading-loose tracking-wide border-x-8 rounded-md border-purple-600`}>
                    ماذا تنتظر! اطلب الآن مشروعك الخاص واستمتع بخدماتنا المميزة
                    <span
                        data-aos="fade-right"
                        data-aos-offset="50"
                        data-aos-easing="linear"
                        data-aos-duration="4000"
                        className="transition duration-500 ease-linear  px-2 my-2 hover:bg-purple-600/30 rounded-xl w-fit underline decoration-dashed decoration-4 underline-offset-8 decoration-purple-500 font-medium">
                        <Link to="/contact">
                            تواصل معنا</Link>
                    </span>
                </h3>


                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                {/*<motion.img*/}
                {/*    variants={moveRightLeftImageFooter}*/}
                {/*    initial="hidden"*/}
                {/*    animate="show"*/}
                {/*    src={footerService} alt="footer image"*/}
                {/*    className={`relative bottom-0 w-[80%] mx-auto opacity-80 mt-32`}/>*/}
            </section>
            {/* End Last Section Service*/}


        </motion.div>
    );
};


export default Services;
