import {useEffect, useState} from "react";
import supabase from "../../supabase/supabase-client";

export interface ContactInfo {
    email: string;
    phone: string;
    whatsapp: string;
    birthday: string;
    location: string;
    social_media: {
        instagram: string;
        facebook: string;
        github: string;
        linkedin: string;
        buymeacoffee: string;
    }
}

export const useContactInfo = () => {
    const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const {data, error} = await supabase
                    .from('SettingsTable')
                    .select('key, value')
                    .in('key', [
                        'email',
                        'phone',
                        'whatsapp',
                        'birth_date',
                        'location',
                        'instagram',
                        'buymeacoffee',
                        'facebook',
                        'github',
                        'linkedin'
                    ]);

                if (error) throw error;

                const info: ContactInfo = {
                    email: data?.find(item => item.key === 'email')?.value || '',
                    phone: data?.find(item => item.key === 'phone')?.value || '',
                    whatsapp: data?.find(item => item.key === 'whatsapp')?.value || '',
                    birthday: data?.find(item => item.key === 'birth_date')?.value || '',
                    location: data?.find(item => item.key === 'location')?.value || '',
                    social_media: {
                        instagram: data?.find(item => item.key === 'instagram')?.value || '',
                        buymeacoffee: data?.find(item => item.key === 'buymeacoffee')?.value || '',
                        facebook: data?.find(item => item.key === 'facebook')?.value || '',
                        github: data?.find(item => item.key === 'github')?.value || '',
                        linkedin: data?.find(item => item.key === 'linkedin')?.value || ''
                    }
                };

                setContactInfo(info);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'حدث خطأ أثناء جلب البيانات');
            } finally {
                setIsLoading(false);
            }
        };

        fetchContactInfo();
    }, []);

    return {contactInfo, isLoading, error};
};
