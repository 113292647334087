import React from 'react';
import {motion, useScroll, useSpring} from "framer-motion";
// @ts-ignore
import {Tables} from "../../database.types";
import supabase from "../supabase/supabase-client";
import CardScroll from "../components/projects/CardScroll";

const Projects = () => {
    const [projects, setProjects] = React.useState<Tables<'ProjectsTable'>[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const {scrollYProgress} = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    React.useEffect(() => {
        const fetchProjects = async () => {
            try {
                const {data, error} = await supabase
                    .from('ProjectsTable')
                    .select('*')
                    .order('created_at', {ascending: false});

                if (error) throw error;
                setProjects(data || []);
            } catch (error) {
                console.error('Error fetching projects:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProjects();
    }, []);

    if (isLoading) {
        return <ProjectsLoader/>;
    }

    return (
        <div className="bg-projects select-none min-h-screen">
            <div className="container mx-auto px-4 py-10">
                {projects.map((project, index) => (
                    <motion.div
                        key={project.id}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: index * 0.2}}
                    >
                        <CardScroll
                            index={index + 1}
                            image={project.images}
                            title={project.title}
                            body={project.description}
                            link={project.link || undefined}
                            linkGitHub={project.link_github || undefined}
                        />
                    </motion.div>
                ))}
            </div>

            {/* Progress Bar */}
            <motion.div
                className="fixed left-0 right-0 h-3 bg-purple-800/60 bottom-[100px] rounded-full"
                style={{scaleX}}
            />
        </div>
    );
};

const ProjectsLoader = () => (
    <div className="min-h-screen flex justify-center items-center bg-projects">
        <div className="flex flex-col items-center gap-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-purple-500"/>
            <p className="text-white text-lg font-medium">جاري تحميل المشاريع...</p>
        </div>
    </div>
);

export default Projects;