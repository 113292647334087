import {motion} from "framer-motion";
import {ContactForm} from '../components/contact-form';
import {useContactInfo} from "../components/contact/useContactInfo";
import {
    FaBirthdayCake,
    FaEnvelope,
    FaFacebook,
    FaGithub,
    FaInstagram,
    FaLinkedin,
    FaMapMarkerAlt,
    FaMugHot,
    FaPhone,
    FaWhatsapp
} from 'react-icons/fa';


const Contact = () => {
    const {contactInfo, isLoading, error} = useContactInfo();

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-purple-500"/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-500 text-center p-4">
                {error}
            </div>
        );
    }

    const contactItems = [
        {
            icon: <FaPhone className="text-2xl text-green-500"/>,
            label: "رقم الهاتف",
            value: contactInfo?.phone,
            href: `tel:${contactInfo?.phone}`,
        },
        {
            icon: <FaWhatsapp className="text-2xl text-green-600"/>,
            label: "واتساب",
            value: contactInfo?.whatsapp,
            href: `https://wa.me/${contactInfo?.whatsapp?.replace(/[^0-9]/g, '')}`,
        },
        {
            icon: <FaEnvelope className="text-2xl text-purple-500"/>,
            label: "البريد الإلكتروني",
            value: contactInfo?.email,
            href: `mailto:${contactInfo?.email}`,
        },
        {
            icon: <FaBirthdayCake className="text-2xl text-pink-500"/>,
            label: "تاريخ الميلاد",
            value: contactInfo?.birthday,
        },
        {
            icon: <FaMapMarkerAlt className="text-2xl text-red-500"/>,
            label: "الموقع",
            value: contactInfo?.location,
        },
    ];

    const socialItems = [
        {
            icon: <FaInstagram className="text-3xl"/>,
            label: "Instagram",
            href: contactInfo?.social_media.instagram,
            color: "hover:text-pink-500",
        },
        {
            icon: <FaFacebook className="text-3xl"/>,
            label: "Facebook",
            href: contactInfo?.social_media.facebook,
            color: "hover:text-blue-500",
        },
        {
            icon: <FaGithub className="text-3xl"/>,
            label: "GitHub",
            href: contactInfo?.social_media.github,
            color: "hover:text-gray-400",
        },
        {
            icon: <FaLinkedin className="text-3xl"/>,
            label: "LinkedIn",
            href: contactInfo?.social_media.linkedin,
            color: "hover:text-blue-600",
        },
        {
            icon: <FaMugHot className="text-3xl"/>,
            label: "Buy me a coffee",
            href: contactInfo?.social_media.buymeacoffee,
            color: "hover:text-yellow-500",
        },
    ];

    return (
        <motion.div
            initial={{y: "100%", opacity: 0}}
            animate={{y: "0%", opacity: 1}}
            transition={{duration: 0.75, ease: "easeOut"}}>

            <section className="max-w-7xl mx-auto px-4 mt-28 py-12">
                {/* Contact Info Cards - 2 per row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
                    {contactItems.map((item, index) => (
                        <motion.div
                            key={item.label}
                            initial={{opacity: 0.5, y: 10}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                            className="bg-gray-900/80 rounded-xl p-6 backdrop-blur-sm border border-gray-700/50 hover:border-purple-500/50 transition-all duration-700"
                        >
                            {item.href ? (
                                <a
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 group"
                                >
                                    <div
                                        className="p-3 bg-gray-800/50 rounded-lg group-hover:bg-gray-800 transition-all duration-300">
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h3 className="text-gray-400 text-sm mb-1">{item.label}</h3>
                                        <p className="text-white font-medium group-hover:text-purple-400 transition-colors duration-300">
                                            {item.value}
                                        </p>
                                    </div>
                                </a>
                            ) : (
                                <div className="flex items-center gap-4">
                                    <div className="p-3 bg-gray-800/50 rounded-lg">
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h3 className="text-gray-400 text-sm mb-1">{item.label}</h3>
                                        <p className="text-white font-medium">{item.value}</p>
                                    </div>
                                </div>
                            )}
                        </motion.div>
                    ))}
                </div>

                {/* Social Media Section */}
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.6}}
                    className="mb-12 bg-gray-900/80 rounded-xl p-6 backdrop-blur-sm border border-gray-700/50"
                >
                    <h2 className="text-2xl text-white text-center mb-6">معرفات التواصل</h2>
                    <div className="flex flex-wrap justify-center gap-8">
                        {socialItems.map((item, index) => (
                            <motion.a
                                key={item.label}
                                href={item.href}
                                target="_blank"
                                rel="noreferrer"
                                initial={{opacity: 0, scale: 0}}
                                animate={{opacity: 1, scale: 1}}
                                transition={{delay: 1 + index * 0.1}}
                                className={`text-white ${item.color} hover:scale-125 transition-all duration-700`}
                                title={item.label}
                            >
                                {item.icon}
                            </motion.a>
                        ))}
                    </div>
                </motion.div>

                {/* Contact Form */}
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.8}}
                >
                    <ContactForm/>
                </motion.div>
            </section>
        </motion.div>
    );
};

export default Contact;