import React, {FormEvent, useEffect, useState} from "react";
import supabase from "../supabase/supabase-client";

export function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [recipientEmail, setRecipientEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const {data, error} = await supabase
                    .from('SettingsTable')
                    .select('value')
                    .eq('key', 'email')
                    .single();

                if (error) throw error;

                setRecipientEmail(data?.value || 'm7mmad.omar0@gmail.com'); // استخدام البريد الاحتياطي إذا لم يتم العثور على القيمة
            } catch (error) {
                console.error('Error fetching email:', error);
                setRecipientEmail('m7mmad.omar0@gmail.com'); // استخدام البريد الاحتياطي في حالة الخطأ
            } finally {
                setIsLoading(false);
            }
        };

        fetchEmail();
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        // تجهيز نص الرسالة
        const mailtoBody = `
الاسم: ${formData.name}
البريد الإلكتروني: ${formData.email}

الرسالة:
${formData.message}
        `.trim();

        // إنشاء رابط mailto
        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(mailtoBody)}`;

        // فتح تطبيق البريد
        window.location.href = mailtoLink;
    };

    if (isLoading) {
        return (
            <div className="w-full flex justify-center items-center py-20">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-purple-500"/>
            </div>
        );
    }

    return (
        <div className="w-full">
            <div className="mt-10 h-96"></div>
            <div className="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8 mb-12">
                <div className="bg-gray-900/60 w-full shadow rounded-2xl p-8 sm:p-12 -mt-72">
                    <p className="text-3xl font-bold leading-7 text-center text-white">
                        تواصل معنا
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="md:flex gap-4 mt-12">
                            <div className="w-full md:w-1/2 flex flex-col">
                                <label className="font-semibold leading-none text-gray-300 text-right">
                                    الاسم:*
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({...prev, name: e.target.value}))}
                                    className="leading-none text-gray-50 p-3 focus:outline-none focus:border-purple-700 mt-4 border-0 bg-gray-800 rounded"
                                />
                            </div>
                            <div className="w-full md:w-1/2 flex flex-col md:mt-0 mt-4">
                                <label className="font-semibold leading-none text-gray-300 text-right">
                                    البريد الإلكتروني:*
                                </label>
                                <input
                                    type="email"
                                    required
                                    value={formData.email}
                                    onChange={(e) => setFormData(prev => ({...prev, email: e.target.value}))}
                                    className="leading-none text-gray-50 p-3 focus:outline-none focus:border-purple-700 mt-4 border-0 bg-gray-800 rounded"
                                />
                            </div>
                        </div>
                        <div className="md:flex items-center mt-8">
                            <div className="w-full flex flex-col">
                                <label className="font-semibold leading-none text-gray-300 text-right">
                                    عنوان الرسالة:*
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={formData.subject}
                                    onChange={(e) => setFormData(prev => ({...prev, subject: e.target.value}))}
                                    className="leading-none text-gray-50 p-3 focus:outline-none focus:border-purple-700 mt-4 border-0 bg-gray-800 rounded"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="w-full flex flex-col mt-8">
                                <label className="font-semibold leading-none text-gray-300 text-right">
                                    الرسالة:*
                                </label>
                                <textarea
                                    required
                                    value={formData.message}
                                    onChange={(e) => setFormData(prev => ({...prev, message: e.target.value}))}
                                    className="h-40 text-base leading-none text-gray-50 p-3 focus:outline-none focus:border-purple-700 mt-4 bg-gray-800 border-0 rounded"
                                ></textarea>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <button
                                type="submit"
                                className="text-right mt-9 font-semibold leading-none text-white py-4 px-10 bg-purple-700 rounded hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 focus:outline-none"
                            >
                                إرسال الرسالة
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
