import React from 'react';
import {motion} from 'framer-motion';
import {SkillCard} from "./SkillCard";
// @ts-ignore
import {Tables} from "../../database.types";
import supabase from "../../supabase/supabase-client";

export const PersonalSkills = () => {
    const [skills, setSkills] = React.useState<Tables<'SkillsTable'>[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchPersonalSkills = async () => {
            try {
                const {data, error} = await supabase
                    .from('SkillsTable')
                    .select('title, percentage')
                    .eq('is_personal', true)
                    .order('percentage', {ascending: false});

                if (error) throw error;
                setSkills(data || []);
            } catch (error) {
                console.error('Error fetching personal skills:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPersonalSkills();
    }, []);

    if (isLoading) {
        return <SkillsLoader/>;
    }

    return (
        <div className="relative select-none">
            <motion.h2
                className="text-center font-bold text-4xl underline underline-offset-6 decoration-purple-500 mt-20"
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                المهارات الشخصية
            </motion.h2>

            <section className="p-2">
                <span className="hidden xl:block absolute -top-32 right-20 bg-skillsPersonal opacity-20 mx-auto mt-20"/>

                <motion.div
                    className="pt-14 text-xl font-medium leading-9 flex justify-center flex-wrap gap-4"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5, delay: 0.2}}
                >
                    {skills.map((skill, index) => (
                        <motion.div
                            key={skill.title}
                            initial={{opacity: 0, x: -20}}
                            animate={{opacity: 1, x: 0}}
                            transition={{
                                duration: 0.5,
                                delay: index * 0.1,
                                ease: "easeOut"
                            }}
                        >
                            <SkillCard
                                title={skill.title}
                                totalSkills={skill.percentage}
                            />
                        </motion.div>
                    ))}
                </motion.div>
            </section>
        </div>
    );
};

const SkillsLoader = () => (
    <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"/>
    </div>
);